import React from "react";
import ReactDOM from "react-dom";
import { DICTIONARY } from "./dictionary.mjs";
import { LETTERS } from "./letters";
import "./index.css";

function Tile(props) {
  const status = props.selected
    ? "selected"
    : props.submitted
    ? "submitted"
    : "available";

  return (
    <button className={`tile ${status}`} onClick={props.onClick}>
      {props.value}
    </button>
  );
}

class Board extends React.Component {
  renderTile(i) {
    const letter = this.props.letters[i];
    return (
      <Tile
        key={i}
        value={letter}
        selected={this.props.selected.includes(i)}
        submitted={this.props.submitted.includes(i)}
        onClick={() => this.props.onClick(i)}
      />
    );
  }

  render() {
    const tiles = [];

    for (let i = 0; i < 25; i++) {
      tiles.push(this.renderTile(i));
    }

    return <div className="board">{tiles}</div>;
  }
}

function Share(props) {
  const shareData = {
    title: props.title,
    text: props.text,
    url: props.url,
  };

  if (!navigator.canShare || !navigator.canShare(shareData)) return props.value;

  const onClick = () => {
    navigator.share(shareData).catch((e) => undefined);
  };

  return <button onClick={() => onClick()}>{props.value} &#x2197;</button>;
}

function Submission(props) {
  return (
    <li>
      {props.value}
      <button onClick={() => props.onClick(props.index)}>&#x2717;</button>
    </li>
  );
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
    const localState = localStorage.getItem(props.day);
    if (localState) {
      try {
        this.state = JSON.parse(localState);
      } catch (e) {
        console.error("Failed to load state from local storage");
      }
    }
    window.addEventListener("keypress", (e) => {
      if (e.code === "Backspace") {
        this.handleBackspace();
      }
    });
  }

  initState() {
    return {
      guess: "",
      guessStatus: "",
      history: [],
      score: 0,
      selected: [],
      submitted: [],
    };
  }

  componentDidUpdate() {
    let state = this.state;
    if (state.guessStatus != "") {
      state.guess = "";
      state.guessStatus = "";
    }
    localStorage.setItem(this.props.day, JSON.stringify(state));
  }

  handleClickTile(i) {
    if (this.state.guessStatus !== "") return;
    if (this.state.submitted.includes(i) || this.state.selected.includes(i))
      return;

    const letter = this.props.letters[i];
    let guess = this.state.guess + letter;
    let history = this.state.history.slice();
    let score = this.state.score;
    let selected = this.state.selected.slice();
    selected.push(i);
    let submitted = this.state.submitted.slice();
    let delay = 0;

    if (guess.length === 5) {
      const correct = DICTIONARY.has(guess);
      const guessStatus = correct ? "accepted" : "rejected";

      if (correct) {
        score++;
        for (const j of selected) {
          submitted.push(j);
        }
        history.push(selected);
      }

      selected = [];

      this.setState({
        guess,
        guessStatus,
        history,
        score,
        selected,
        submitted,
      });

      guess = "";
      delay = 800;
    }

    setTimeout(() => {
      this.setState({ guess, guessStatus: "", score, selected });
    }, delay);
  }

  handleBackspace() {
    const selected = this.state.selected.slice();
    selected.pop();
    const guess = selected.map((i) => this.props.letters[i]).join("");

    this.setState({ guess, selected });
  }

  handleClickDeleteHistory(i) {
    const history = this.state.history.slice();
    const submission = history[i];
    const submitted = this.state.submitted.slice();

    history.splice(i, 1);
    for (const j of submission) {
      submitted.splice(submitted.indexOf(j), 1);
    }
    this.setState({ history, submitted });
  }

  renderHistory(submission, i) {
    const value = submission.map((j) => this.props.letters[j]).join("");

    return (
      <Submission
        key={i}
        index={i}
        value={value}
        onClick={(i) => this.handleClickDeleteHistory(i)}
      />
    );
  }

  render() {
    const backspaceClassname = this.state.guess.length > 0 ? "" : "hide";
    const history = this.state.history.map((submission, i) =>
      this.renderHistory(submission, i)
    );
    const movesText = this.state.score === 1 ? "move" : "moves";
    const scoreText = `${this.state.score} ${movesText}`;
    let shareText = `Deword Game #${this.props.day}`;
    if (this.state.history.length === 5) shareText += ` ${scoreText}`;
    const status =
      this.state.history.length === 5
        ? `You won in ${this.state.score} moves!`
        : scoreText;

    return (
      <div className="game">
        <div className="status">
          <Share
            title="Deword Game"
            text={shareText}
            url="https://www.dewordgame.com"
            value={status}
          />
        </div>
        <Board
          letters={this.props.letters}
          selected={this.state.selected}
          submitted={this.state.submitted}
          onClick={(i) => this.handleClickTile(i)}
        />
        <div className="guess">
          <div className={`word ${this.state.guessStatus}`}>
            {this.state.guess}
          </div>
          <button
            aria-label="Backspace"
            className={backspaceClassname}
            onClick={() => this.handleBackspace()}
          >
            &#x232B;
          </button>
        </div>
        <ul className="history">{history}</ul>
      </div>
    );
  }
}

const start = new Date(2022, 1, 16);
const midnight = new Date();
midnight.setHours(0, 0, 0, 0);
const day = Math.ceil(
  (midnight.getTime() - start.getTime()) / (1000 * 3600 * 24)
);
const letters = LETTERS[day];

ReactDOM.render(
  <Game day={day + 1} letters={letters} />,
  document.getElementById("root")
);

document.addEventListener(
  "touchmove",
  (e) => {
    e.preventDefault();
  },
  { passive: false }
);
